@import '~antd/dist/antd.min.css';

/* .App {
  text-align: center;
} */

body, #vulcanRoot {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.padded {
  padding: 16px;
}

.padded-lg {
  padding: 30px;
}

.block {
  display: block;
  width: 100%;
  height: 100%;
}

.ant-select-allow-options-wrap  .ant-select-item-option-content {
  white-space: normal;
  padding-left: 0.7em;
  text-indent: -0.7em;
}

.ant-tooltip-auto-width, .ant-tooltip-auto-width  .ant-tooltip-content {
  max-width: none;
}

.ant-tooltip-body {
  background-color: #2d3136;
}

.ant-select.ant-select-single.ant-select-show-search.ant-select-open .ant-select-selection-item {
  pointer-events: none;
}

.ant-form.processing {
  opacity: 0.6;
}

.ant-form.processing, .ant-form.processing input {
  pointer-events: none;
}

.ant-select-tree-custom {
  padding: 4px 0;
}

.ant-select-tree-custom .ant-select-tree-switcher {
  display: none;
}

.ant-select-tree-custom .ant-select-tree-node-content-wrapper {
  padding: 5px 12px;
}

.ant-select-tree-custom .ant-select-tree-node-selected {
  font-weight: bold;
}

.ant-select-tree-custom .ant-select-tree-switcher:not(.ant-select-tree-switcher-noop) + .ant-select-tree-node-content-wrapper {
  pointer-events: none;
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
}

.ant-select-tree-custom.ant-tree-select-dropdown .ant-select-tree-list-holder-body .ant-select-tree-treenode {
  padding: 0px;
}

.ant-select-item-group {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
}


h1.ant-typography, .ant-typography h1 {
  font-size: 30px;
  line-height: 1.35;
}

h2.ant-typography, .ant-typography h2 {
  font-size: 26px;
  line-height: 1.35;
}

h3.ant-typography, .ant-typography h3 {
  font-size: 24px;
  line-height: 1.4;
}

h4.ant-typography, .ant-typography h4 {
  font-size: 20px;
  line-height: 1.4;
}

.ant-drawer.ant-drawer-open.no-mask .ant-drawer-content-wrapper {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-title.thin,
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph.thin > li {
  height: 12px;
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph.thin {
  margin-top: 14px;
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-title.thin,
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph.thin > li+li {
  margin-top: 14px;
}

/* Turn on custom 8px wide scrollbar */
.os-windows ::-webkit-scrollbar {
  /* This is more usable for users trying to click it. */
  background-color: rgba(0,0,0,0);
}

.os-windows ::-webkit-scrollbar:vertical {
  width: 12px;
}
.os-windows ::-webkit-scrollbar:horizontal {
  height: 12px;
}
.os-windows ::-webkit-scrollbar:active {
  background-color: rgba(0, 0, 0, 0.06);
}
.os-windows ::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.5);
}
.os-windows ::-webkit-scrollbar-thumb:active {
  background-color: rgba(0,0,0,0.61);
}
.os-windows ::-webkit-scrollbar-thumb, .os-windows ::-webkit-scrollbar-thumb:active {
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
}
.os-windows ::-webkit-scrollbar-thumb:vertical {
  min-height: 12px;
}
.os-windows ::-webkit-scrollbar-thumb:horizontal {
  min-width: 12px;
}

.is-mobile .ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-popover-hide-arrow .ant-popover-arrow {
  visibility: hidden;
}

.ant-popover-no-content-padding .ant-popover-inner-content {
  padding: 0;
}

#upscope___promo-link-container {
  display: none;
}

.support-message {
  width: 250px;
  padding: 12px 20px;
  border-radius: 12px !important;
}
